<template>
  <div>
    <div class="card-header">
      <h4>History Point</h4>
    </div>
    <div class="card-body">
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <v-data-table
        :headers="headers"
        :items="historyPoint"
        item-key="id"
        :search="search"
        hide-default-footer
        :page.sync="page"
        :items-per-page="itemsPerPage"
        class="elevation-1"
        @page-count="pageCount = $event"
        v-else
      >
        <template v-slot:item="row">
          <tr>
            <td>
              {{ new Date(row.item.created_at).toDateString() }}
            </td>
            <td>Rp. {{ formatPrice(row.item.mount) }}</td>
            <td>{{ row.item.information }}</td>
            <td>{{ row.item.status }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["historyPoint"],
  data() {
    return {
      itemsPerPage: 10,
      page: 1,
      search: "",
      loading: false,
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Date",
          value: "created_at",
        },
        {
          text: "Amount",
          value: "mount",
        },
        {
          text: "Information",
          value: "information",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
    },
  },
};
</script>
